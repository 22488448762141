import React from 'react';
import axios from 'axios';
import ContactForm from '../components/form';

export default class PricingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      sectionInfo: null
    }
  }

  async componentDidMount() {
    try {
      const WOW = require('wowjs')
      const wow = new WOW.WOW()
      wow.init()
    } catch (e) {
      console.error(e)
    }

    try {
      const allPartners = await axios.get(`${process.env.API_URL}/umbraco/api/partner/getall`);
      const partnersInfo = await axios.get(`${process.env.API_URL}/umbraco/api/partner/get`);

      this.setState({
        data: allPartners.data,
        sectionInfo: partnersInfo.data
      })
    } catch (e) {

    }
  }

  render() {
    const { data, sectionInfo } = this.state

    let description = '';
    let contactDescription = '';

    if (sectionInfo) {
      description = sectionInfo.Intro;
      contactDescription = sectionInfo.ContactInfo;
    }

    return (
      <div>
        <section className="section-top">
          <div className="container">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="section-top-title">
                <h2>Parceiros</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12" />
          </div>
        </section>
        <div className="section-padding section-title section-title-mb-zero text-center">
          <div className="p-md" dangerouslySetInnerHTML={{__html: description}} />          
          <span></span>
        </div>
        <section className="our_partners">
          <div className="container">
            <div className="row text-center">
              {data.map((partner, idx) => {
                var link = partner.Link || '#';

                return (
                  <div key={idx} className="col-md-4 col-sm-12">
                    <a target="_blank" href={link}>
                      <div className="single_activites single_activites_border_md card">
                        <img className="padding-md" src={`${process.env.API_URL}${partner.Photo}`} />
                        <h4 className="text">{partner.Name}</h4>
                      </div>
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="section-padding">
          <div className="container">
            <div className="row">
              <div className="section-title text-center wow zoomIn">
                <h2>Contacte-nos</h2>
                <span />
                <div dangerouslySetInnerHTML={{__html: contactDescription}} />                
              </div>
              <div className="col-md-8 col-md-offset-2 col-sm-12 col-xs-12">
                <ContactForm />
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
